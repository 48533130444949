import KsLayout from '@/layout';
import { Button } from '@components/primitive/Button';
import { KaImage } from '@components/primitive/Image';
import verifyStyles from '@scss/pages/verify.scss';
import { routes } from '@utils/routes';
import { useRouter } from 'next/router';

export default function Error404() {
  const router = useRouter();

  return (
    <>
      <style jsx>{verifyStyles}</style>
      <KsLayout title="Register Verify">
        <div className="ks-verify">
          <div className="image">
            <KaImage
              src="/images/404.jpg"
              objectFit="cover"
              width={368}
              height={368}
              useNextImage
            />
          </div>
          <h1 className="title">không tìm thấy trang</h1>
          <Button onClick={() => router.push(routes.HOME)}>Trang chủ</Button>
        </div>
      </KsLayout>
    </>
  );
}
