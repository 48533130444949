import Error404 from '@containers/Error/404';

const index = () => <Error404 />;

export const getStaticProps = () => {
  return {
    props: {},
    revalidate: 60,
  };
};

export default index;
